import { useEffect, useState } from "react"
import api from "../component/items/api"

const useRoomList = (building) => {
    const [roomList, setRoomList] = useState([])
    useEffect(() => {
        api.get(`getRoomList/${building.buildingId}`).then((response) => {
        //console.log(response.data.roomList)
        setRoomList(response.data.roomList)
        })
    },[building])
    return roomList
}
export default useRoomList