import { useEffect, useState } from "react"
import api from "../component/items/api"

const useSignBoardList = (userId, load) => {
    const [signBoardList, setSignBoardList] = useState([])
    useEffect(() => {
        api.get(`getSignBoardList/${userId}`).then((response) => {
            console.log(response.data.signBoardList)
            setSignBoardList(response.data.signBoardList)
        })
    },[userId, load])
    return signBoardList
}
export default useSignBoardList