import { useEffect, useState } from "react";
import api from "../../items/api";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { Check, AddToPhotos } from "@mui/icons-material";
import { useHistory, Link, useLocation } from "react-router-dom";
import { jsonToBlob } from "../../../libs/file/blob";
import { createImageUrl } from "../../../libs/file/image";

const EditSignBoardForm = (props) => {
  const { userId } = props;
  const history = useHistory();
  const { state } = useLocation();
  const [file, setFile] = useState("");
  const [isRegistered, setIsResistered] = useState(false);
  const [signBoardName, setSignBoardName] = useState(state.data.signBoardName);
  const [image, setImage] = useState(state.data.image);
  const [url, setUrl] = useState(state.data.url);
  const [loading, setLoading] = useState(false);

  /* エラーメッセージ用 */
  const [roomNameErr, setRoomNameErr] = useState("");

  const register = (e) => {
    e.preventDefault();
    setRoomNameErr(false);
    /* まずは全てのエラーを削除する */
    if (signBoardName !== "") {
      setIsResistered(true);
      const jsonBlob = jsonToBlob({
        signBoardId: state.data.signBoardId,
        userId: userId,
        signBoardName: signBoardName,
        image: image,
        url: url,
        type: 0
      })
      const formData = new FormData()
      formData.append("image", file)
      formData.append("json", jsonBlob)
      api.post("editSignBoard", formData).then(()=>{
          setIsResistered(true)
      }).catch((e)=>{
          console.error(e)
          // TODO: エラー処理
      }).finally(()=>{
          setLoading(false)
      })
    } else {
      setRoomNameErr(true);
    }
  };

  useEffect(() => {
    if (file === "") return
    const url = createImageUrl(file)
    setImage(url)
  }, [file]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      {!isRegistered ? (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <AddToPhotos />
              </Avatar>
              <Typography component="h1" variant="h5">
                看板編集
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="storeName"
                      required
                      fullWidth
                      id="storeName"
                      label="看板名"
                      error={roomNameErr}
                      helperText={roomNameErr ? "この項目は必須です" : null}
                      autoFocus
                      value={signBoardName}
                      onChange={(e) => {
                        setSignBoardName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="postCode"
                      required
                      fullWidth
                      id="postCode"
                      label="リンクURL"
                      value={url}
                      autoFocus
                      onChange={(e) => {
                        setUrl(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      component="label"
                      variant={image === "" ? "outlined" : "text"}
                    >
                      {image === "" ? (
                        "画像ファイルを選択する"
                      ) : (
                        <>
                          <Check />
                          画像ファイルが選択されました
                        </>
                      )}

                      <input
                        type="file"
                        className="inputFileBtnHide"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </Button>
                    <img alt="" src={image} style={{ width: "100%" }} />
                  </Grid>
                </Grid>
                <Button
                  onClick={register}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  更新
                </Button>
              </Box>
              <Link onClick={goBack} variant="body2">
                戻る
              </Link>
            </Box>
          </Container>
        </>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <Check />
            </Avatar>
            <Typography component="h1" variant="h5">
              看板の編集が完了しました。
            </Typography>
            <Link onClick={goBack} variant="body2">
              戻る
            </Link>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default EditSignBoardForm;
