import { useState } from "react";
import api from "../../items/api";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Check, AddToPhotos } from "@mui/icons-material";
import { useHistory, Link, useLocation } from "react-router-dom";
import useSignBoardList from "../../../hooks/useSignBoardList";

const AddInstallSignboard = ({ userId }) => {
  const history = useHistory();
  const { state } = useLocation();
  const buildingId = state;
  const [prs, setPrs] = useState("");
  const [signBoardId, setSignBoardId] = useState("");
  const [isRegistered, setIsResistered] = useState(false);

  /* エラーメッセージ用 */
  const [signBoardIdErr, setSignBoardIdErr] = useState("");

  const register = (e) => {
    e.preventDefault();
    setSignBoardIdErr(false);
    /* まずは全てのエラーを削除する */
    if (signBoardId !== "") {
      setIsResistered(true);
      api.post("installSignBoard", {
        userId: userId,
        buildingId: buildingId,
        signBoardId: signBoardId,
        prs: prs,
      });
    } else {
      setSignBoardIdErr(true);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      {!isRegistered ? (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <AddToPhotos />
              </Avatar>
              <Typography component="h1" variant="h5">
                建物看板登録
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectSignBoard
                      userId={userId}
                      signBoardId={signBoardId}
                      setSignBoardId={setSignBoardId}
                      signBoardIdErr={signBoardIdErr}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="signBoardPrs"
                      id="signBoardPrs"
                      required
                      fullWidth
                      label="配置"
                      onChange={(e) => {
                        setPrs(e.target.value);
                      }}
                      xs={12}
                      value={prs}
                    />
                  </Grid>
                </Grid>
                <Button
                  onClick={register}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  登録
                </Button>
              </Box>
              <Link onClick={goBack} variant="body2">
                戻る
              </Link>
            </Box>
          </Container>
        </>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <Check />
            </Avatar>
            <Typography component="h1" variant="h5">
              看板の登録が完了しました。
            </Typography>
            <Link onClick={goBack} variant="body2">
              戻る
            </Link>
          </Box>
        </Container>
      )}
    </div>
  );
};
export default AddInstallSignboard;

const SelectSignBoard = ({
  userId,
  signBoardId,
  setSignBoardId,
  signBoardIdErr,
}) => {
  const signBoardList = useSignBoardList(userId);
  const handleChange = (e) => {
    setSignBoardId(e.target.value);
  };
  return (
    <>
      <InputLabel>看板選択</InputLabel>
      <Select
        name="signBoard"
        id="signBoard"
        labelId="signBoard"
        label="看板選択"
        value={signBoardId}
        onChange={handleChange}
        required
        fullWidth={true}
        error={signBoardIdErr}
        helperText={signBoardIdErr ? "この項目は必須です" : null}
        sx={{ minWidth: 200 }}
      >
        {signBoardList.map((v) => (
          <MenuItem key={v.signBoardId} value={v.signBoardId}>
            {v.signBoardName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
