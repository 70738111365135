import { useEffect, useState } from "react"
import api from "../component/items/api"

const useInstallSignBoardList = (building) => {
    const [installSignBoardList, setInstallSignBoardList] = useState([])
    useEffect(() => {
        api.get(`getInstallSignBoardList/${building.buildingId}`).then(({ data }) => {
        setInstallSignBoardList(data.installSignBoardList)
        })
    },[building])
    return installSignBoardList
}
export default useInstallSignBoardList