import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useState, useEffect } from "react"
import api from "./component/items/api"
import { AddBuildingForm, Login, SignUp, Top, EditBuildingForm, AddRoom, EditRoom } from "./component/index"
import './App.css';
import Header from './component/ui/Header';
import Profile from './component/profile/Profile';
import ResetPass1 from './component/profile/ResetPass1';
import ResetPass2 from './component/profile/ResetPass2';
import SendResetEmail from './component/profile/SendResetEmail';
import AddSignboard from './component/campanyPage/SignBoard/AddSignboard';
import EditSignBoardForm from './component/campanyPage/SignBoard/EditSignBoardForm';
import AddInstallSignboard from './component/campanyPage/InstallSignBoard/AddInstallSignboard';

function App() {
  const [userId, setUserId] = useState(0)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    api.get("auth", { withCredentials: true }).then((response) => {
      console.log(response.data)
      if (response.data.auth) {
        console.log(response.data)
        setUserId(response.data.userId)
        setIsLoggedIn(true)
      }
    })
  }, [])

  const login = (id) => {
    setUserId(id)
    setIsLoggedIn(true)
  }

  const logout = () => {
    setIsLoggedIn(false)
    setUserId(null)
    api.post("logout", { withCredentials: true }).then((response) => {
      console.log(response.data)
    })
  }

  return (
    <>
      <BrowserRouter>
        <Header logout={logout} isLoggedIn={isLoggedIn}/>
        <Switch>
          <Route exact path="/">
            <Top isLoggedIn={isLoggedIn} userId={userId}/>
          </Route>

          <Route path="/addBuilding">
            <AddBuildingForm userId={userId}/>
          </Route>

          <Route path="/addSignboard">
            <AddSignboard userId={userId} />
          </Route>

          <Route path="/addSignboard">
            <AddSignboard userId={userId} />
          </Route>

          <Route path="/editBuilding">
            <EditBuildingForm userId={userId}/>
          </Route>

          <Route path="/editSignBoard">
            <EditSignBoardForm userId={userId}/>
          </Route>

          <Route path="/addInstallSignboard">
            <AddInstallSignboard userId={userId} />
          </Route>

          <Route path="/addRoom">
            <AddRoom />
          </Route>

          <Route path="/EditRoom">
            <EditRoom />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/login">
            <Login login={login} />
          </Route>

          <Route path="/sendResetEmail">
            <SendResetEmail />
          </Route>

          <Route path="/resetPassword/:resetCode">
            <ResetPass2 />
          </Route>
          

          <Route
            path="/profile"
            render={({ match: { url } }) => (
              <>
                <Switch>
                  <Route path={`${url}`} exact>
                    <Profile userId={userId} isLoggedIn={isLoggedIn}/>
                  </Route>
                  <Route path={`${url}/resetPassword`}>
                    <ResetPass1 userId={userId}/>
                  </Route>
                </Switch>
              </>
            )}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App