import { useState } from "react"
import { Link, useHistory } from 'react-router-dom';
import api from "../items/api"
import { Avatar, Button, Container, CssBaseline, fabClasses, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LockOutlined, Check } from "@mui/icons-material";
api.defaults.withCredentials = true

function ResetPass1({userId}) {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPassCheck, setNewPassCheck] = useState("")
    const [currentPassErr, setCurrentPassErr] = useState(false)
    const [newPassErr, setNewPassErr] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)

    const history = useHistory();

    /*ログインボタンが押された時 */
    const registerNewPassword = (e) => {
        e.preventDefault()
        setCurrentPassErr(false)
        setNewPassErr(false)
        if(newPassword === newPassCheck){
            api.post("resetPassword", {
                userId:userId,
                prePassword:currentPassword,
                newPassword:newPassword
            }).then((response) => {
                if(response.data.result){
                    setIsRegistered(true)
                }else{
                    setCurrentPassErr(true)
                }
            })
        }else{
            setNewPassErr(true)
        }
    }

    /*ホームに戻る */
    const backpage = (e) => {
        e.preventDefault();
        history.goBack()
    }

    return (
        <>
            {
                !isRegistered ?
                    <>
                        < Container component="main" maxWidth="xs" >
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <LockOutlined />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    パスワード再設定
                                </Typography>
                                <Box component="form" noValidate sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password"
                                                error={currentPassErr}
                                                helperText={currentPassErr ? "パスワードが間違っています" : null}
                                                label="現在のパスワード"
                                                type="password"
                                                id="password"
                                                autoComplete="password"
                                                onChange={(e) => { setCurrentPassword(e.target.value) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password"
                                                label="新しいパスワード"
                                                type="password"
                                                onChange={(e) => { setNewPassword(e.target.value) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                error={newPassErr}
                                                helperText={newPassErr ? "パスワードが一致しません" : null}
                                                name="password"
                                                label="新しいパスワード(確認)"
                                                type="password"
                                                onChange={(e) => { setNewPassCheck(e.target.value) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        onClick={registerNewPassword}
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={currentPassword === "" || newPassword === "" || newPassCheck === "" ? true : false}
                                    >
                                        決定
                                    </Button>
                                    <Grid container>
                                        <Link onClick={backpage} variant="body2">
                                            戻る
                                        </Link>
                                    </Grid>
                                </Box>
                            </Box>
                        </Container >
                    </ >
                    :
                    <>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <Check />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    パスワードの再設定が完了しました
                                </Typography>
                                <Button
                                    onClick={()=>{history.push("/profile")}}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    戻る
                                </Button>
                            </Box>
                        </Container>
                    </>
            }
        </>
    )
}
export default ResetPass1