import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import api from "../../items/api";
import { Modal, Tooltip, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";

const CollapsibleSignBoardTable = ({ signBoardList, loading }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <SignBoardTableHead />
        <TableBody>
          {signBoardList.map((signBoard, i) => (
            <SignBoardTableRow
              key={i}
              signBoard={signBoard}
              sx={{ width: "100%" }}
              loading={loading}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CollapsibleSignBoardTable;

/** ### 看板テーブル用ヘッダー  */
const SignBoardTableHead = () => (
  <TableHead>
    <TableRow>
      <TableCell></TableCell>
      <TableCell>看板名</TableCell>
      <TableCell>リンクURL</TableCell>
      <TableCell>画像</TableCell>
      <TableCell align="right">編集・削除</TableCell>
    </TableRow>
  </TableHead>
);

/**
 * ### 看板テーブル用データ列
 * * `signBoard` -
 * * `loading` -
 * @param {{ signBoard, loading }} params
 */
export const SignBoardTableRow = ({ signBoard, loading }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deleteSignBoard = () => {
    api
      .post(`deleteSignBoard`, { signBoardId: signBoard.signBoardId })
      .then((response) => {
        loading();
        setIsModalOpen(false);
      });
  };
  const goEditSignBoard = () => {
    history.push({
      pathname: "/editSignboard",
      state: { data: signBoard },
    });
  };

  return (
    <React.Fragment>
      <TableRow sx={{ width: "100%" }}>
        <TableCell>
          {false && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell scope="row" component="th">
          {signBoard.signBoardName}
        </TableCell>
        <TableCell scope="row" component="th">
          <a href={signBoard.url} target="_blank" rel="noopener noreferrer">
            {signBoard.url}
          </a>
        </TableCell>
        <TableCell scope="row" component="th">
          <img src={signBoard.image} alt="" style={{ maxHeight: "30px" }} />
        </TableCell>
        <TableCell align="right">
          <Box sx={{ margin: 0, marginLeft: "auto" }}>
            <Tooltip title="編集">
              <IconButton onClick={goEditSignBoard} sx={{ padding: 0 }}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="削除"
              sx={{ marginLeft: "50px", backgroundColor: "pink" }}
            >
              <IconButton
                onClick={() => setIsModalOpen(true)}
                sx={{ padding: 0, marginLeft: "10px" }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: "1px 	#e6e6e6 solid" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <SignBoardDetail open={open} setIsModalOpen={setIsModalOpen} />
        </TableCell>
      </TableRow>
      <CheckDeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClick={deleteSignBoard}
      />
    </React.Fragment>
  );
};

const SignBoardDetail = ({ open, signBoard, setIsModalOpen, setRoomId }) => {
  const history = useHistory();
  const [roomList, setRoomList] = useState([]);
  const goEditRoom = (roomId, roomName, rent, floor, image) => {
    history.push({
      pathname: "/editRoom",
      state: {
        roomId: roomId,
        roomName: roomName,
        rent: rent,
        floor: floor,
        image: image,
      },
    });
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Typography
          variant="h6"
          gutterBottom
          component="label"
          sx={{ fontSize: "1000" }}
        >
          部屋情報
        </Typography>
        <Button
          onClick={() => {
            history.push({
              pathname: "/addRoom",
              state: signBoard.signBoardId,
            });
          }}
          variant="outlined"
          sx={{ marginLeft: "15px" }}
        >
          部屋を追加
        </Button>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>部屋名</TableCell>
              <TableCell>家賃</TableCell>
              <TableCell align="right">階数</TableCell>
              <TableCell align="right">イメージ画像</TableCell>
              <TableCell align="right">編集・削除</TableCell>
            </TableRow>
          </TableHead>
          {roomList.length > 0 ? (
            <>
              {roomList.map((data, i) => {
                return (
                  <TableBody key={i}>
                    <TableCell>{data.roomName}</TableCell>
                    <TableCell>{data.rent}</TableCell>
                    <TableCell align="right">{data.floor}</TableCell>
                    <TableCell align="right">
                      <a href={data.image}>ダウンロード</a>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ margin: 0, marginLeft: "auto" }}>
                        <Tooltip title="編集">
                          <IconButton
                            onClick={() => {
                              goEditRoom(
                                data.roomId,
                                data.roomName,
                                data.rent,
                                data.floor,
                                data.image
                              );
                            }}
                            sx={{ padding: 0 }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="削除"
                          sx={{
                            marginLeft: "50px",
                            backgroundColor: "pink",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setIsModalOpen(true);
                              setRoomId(data.roomId);
                            }}
                            sx={{ padding: 0, marginLeft: "10px" }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableBody>
                );
              })}
            </>
          ) : null}
        </Table>
      </Box>
    </Collapse>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const CheckDeleteModal = ({ isModalOpen, setIsModalOpen, handleClick }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          本当に削除しますか？
        </Typography>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{ marginTop: "30px" }}
          color="error"
        >
          削除する
        </Button>
        <Button
          onClick={() => setIsModalOpen(false)}
          variant="outlined"
          sx={{ marginTop: "30px", marginLeft: "30px" }}
          color="success"
        >
          閉じる
        </Button>
      </Box>
    </Modal>
  );
};
