import TopLogin from "./TopLogin"
import { Avatar, Button, Container, CssBaseline, Grid, Box, TextField, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";

const Top = (props) => {
    const { isLoggedIn, userId } = props
    const history = useHistory()

    if(isLoggedIn){
        return(
            <TopLogin userId={userId}/>
        )
    }else{
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h5" variant="h5">
                        ログインが必要です
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={()=>{history.push("/login")}}
                            sx={{ mt: 3, mb: 2 }}
                            minWidth="xs"
                        >
                            ログイン
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link to="/signup" variant="body2">
                                    アカウントをお持ちでない場合は登録
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }
    
}
export default Top