import { useState } from "react"
import { useHistory } from "react-router-dom"
import CollapsibleTable from "./Table"
import { Box, Container, TableContainer, Paper } from "@mui/material"
import { Button } from "@mui/material"
import CollapsibleSignBoardTable from "./SignBoard/SignboardTable"
import useSignBoardList from "../../hooks/useSignBoardList"
import useBuildingList from "../../hooks/useBuildingList"

const TopLogin = (props) => {
    const { userId } = props
    const history = useHistory()
    const [load, setLoad] = useState(false)
    const buildingList = useBuildingList(userId, load)
    const signBoardList = useSignBoardList(userId, load)
    const loading = () => { setLoad(!load) }
    
    return(
        <div>
            <Container>
                <section>
                    <h3>建物リスト</h3>
                    {
                        buildingList.length > 0 
                        ?
                        <TableContainer component={Paper} sx={{mt:3}}>
                            <CollapsibleTable 
                                buildingList={buildingList} 
                                signBoardList={signBoardList}
                                loading={loading}
                            />
                        </TableContainer>
                        :
                        <h4>登録された店舗はありません。</h4>
                    }
                    <Box sx={{marginTop:"30px"}}>
                        <Button onClick={()=>{history.push("/addBuilding")}} variant="outlined">
                            建物を追加
                        </Button>
                    </Box>
                </section>

                <section>
                    <h3>看板リスト</h3>
                    {
                        signBoardList.length > 0 
                        ?
                        <TableContainer component={Paper} sx={{mt:3}}>
                            <CollapsibleSignBoardTable signBoardList={signBoardList} loading={loading}/>
                        </TableContainer>
                        :
                        <h4>登録された看板はありません。</h4>
                    }
                    <Box sx={{marginTop:"30px"}}>
                        <Button onClick={()=>{history.push("/addSignboard")}} variant="outlined">
                            看板を追加
                        </Button>
                    </Box>
                </section>
            </Container>
        </div>
    )
}
export default TopLogin
