import { useState, useEffect } from "react";
import api from "../items/api";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  Divider,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  AccountCircle,
  Edit,
  SettingsOverscanOutlined,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import ListRowItem from "../ui/ListRowItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Profile = ({ userId, isLoggedIn }) => {
  const [userName, setUserName] = useState("");
  const [homePage, setHomePage] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [inquiryUrl, setInquiryUrl] = useState("");
  const [documentRequestUrl, setDocumentRequestUrl] = useState("");
  const [item, setItem] = useState("");
  const [newValue, setNewValue] = useState("");
  const [valueErr, setValueErr] = useState(false);
  const [mOpen, setMOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isChange, setIsChange] = useState(false);

  const history = useHistory();

  /* リストの作成 */
  useEffect(() => {
    setList([
      { item: "会社名", value: userName },
      { item: "ホームページ", value: homePage },
      { item: "メールアドレス", value: email },
      { item: "電話番号", value: phoneNumber },
      { item: "郵便番号", value: zipCode },
      { item: "住所", value: address },
      { item: "お問い合わせURL", value: inquiryUrl },
      { item: "資料請求URL", value: documentRequestUrl },
    ]);
  }, [
    userName,
    homePage,
    email,
    phoneNumber,
    zipCode,
    address,
    inquiryUrl,
    documentRequestUrl,
  ]);

  /* ユーザーの情報取得 */
  useEffect(() => {
    api.get(`getUserInfo/${userId}`).then((response) => {
      setUserName(response.data.userName);
      setHomePage(response.data.homePage);
      setEmail(response.data.email);
      setPhoneNumber(response.data.phoneNumber);
      setZipCode(response.data.zipCode);
      setAddress(response.data.address);
      setInquiryUrl(response.data.inquiryURL);
      setDocumentRequestUrl(response.data.documentURL);
    });
  }, [userId]);

  /* 編集 */
  const completeEdit = (item, value) => {
    setValueErr(false);
    if (value === "") {
      setValueErr(true);
      return;
    }
    if (item === "会社名") {
      api
        .post("resetUserName", {
          userId: userId,
          userName: value,
        })
        .then((response) => {
          setUserName(value);
        });
    } else if (item === "ホームページ") {
      api
        .post("resetHomePage", {
          userId: userId,
          homePage: value,
        })
        .then((response) => {
          setHomePage(value);
        });
    } else if (item === "メールアドレス") {
      api
        .post("resetEmail", {
          userId: userId,
          email: value,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            setEmail(value);
          } else {
            alert("このメールアドレスはすでに登録されています");
          }
        });
    } else if (item === "電話番号") {
      api
        .post("resetPhoneNumber", {
          userId: userId,
          phoneNumber: value,
        })
        .then((response) => {
          if (response.data.result) {
            setPhoneNumber(value);
          } else {
            alert("このメールアドレスはすでに登録されています");
          }
        });
    } else if (item === "郵便番号") {
      api
        .post("resetZipCode", {
          userId: userId,
          zipCode: value,
        })
        .then((response) => {
          setZipCode(value);
        });
    } else if (item === "お問い合わせURL") {
      api
        .post("resetInquiryURL", {
          userId: userId,
          inquiryURL: value,
        })
        .then((response) => {
          setInquiryUrl(value);
        });
    } else if (item === "資料請求URL") {
      api
        .post("resetDocumentURL", {
          userId: userId,
          documentURL: value,
        })
        .then((response) => {
          setDocumentRequestUrl(value);
        });
    } else {
      api
        .post("resetAddress", {
          userId: userId,
          address: value,
        })
        .then((response) => {
          setAddress(value);
        });
    }
    setNewValue("");
    setMOpen(false);
    setItem("");
    setIsChange(false);
  };

  const editMode = (item, currentValue) => {
    setItem(item);
    setMOpen(true);
    setNewValue(currentValue);
    setValueErr(false);
  };

  return (
    <>
      {isLoggedIn ? (
        <>
          <Container component="main" maxWidth="xs">
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <AccountCircle />
                </Avatar>
              </Box>
              <Typography variant="h5" component="h1">
                プロフィール
              </Typography>

              <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableBody>
                    {list.map((data, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          <TableCell align="left">{data.item}</TableCell>
                          <TableCell align="left">{data.value}</TableCell>
                          <TableCell align="right">
                            <Button
                              onClick={() => {
                                editMode(data.item, data.value);
                              }}
                            >
                              編集
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                sx={{ width: "100%", mt: 2 }}
                onClick={() => {
                  history.push("/profile/resetPassword");
                }}
              >
                パスワードを再設定する
              </Button>
            </Box>
          </Container>
          <Modal
            open={mOpen}
            onClose={() => {
              setMOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <Edit />
                </Avatar>
              </Box>
              <Typography variant="h5" component="h1">
                編集
              </Typography>
              <TextField
                error={valueErr}
                helperText={valueErr ? "値を入力してください" : null}
                margin="normal"
                required
                fullWidth
                name="password"
                label={item}
                value={newValue}
                type={item === "メールアドレス" ? "email" : "text"}
                onChange={(e) => {
                  setNewValue(e.target.value);
                  setIsChange(true);
                }}
              />
              <Button
                onClick={() => {
                  completeEdit(item, newValue);
                }}
                variant="contained"
                sx={{ marginTop: "30px", width: "100%" }}
                disabled={isChange ? false : true}
              >
                完了
              </Button>
              <br />
              <Button
                onClick={() => {
                  setMOpen(false);
                }}
                variant="outlined"
                sx={{ marginTop: "30px", width: "100%" }}
              >
                閉じる
              </Button>
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
};
export default Profile;
