import { useState, useEffect } from "react"
import api from "../items/api"
import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { Check, AddToPhotos } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { createImageUrl } from "../../libs/file/image";

const AddBuildingForm = (props) => {
    // buildingName, companyName, zipCode, address, contact, url
    const { userId } = props
    console.log(userId)
    const history = useHistory()
    const [isRegistered, setIsRegistered] = useState(false)
    const [buildingName, setBuildingName] = useState("")
    const [constructionDate, setConstructionDate] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [address, setAddress] = useState("")
    const [contact, setContact] = useState("")
    const [objFile, setObjFile] = useState("")
    const [url, setUrl] = useState("")
    const [imgFile, setImgFile] = useState("")
    const [buildingImageList, setBuildingImageList] = useState([])
    const [buildingImageFileList, setBuildingImageFileList] = useState([])
    const [buildingNameErr, setBuildingNameErr] = useState(false)
    const [constructionDateErr, setConstructionDateErr] = useState(false)
    const [companyNameErr, setCompanyNameErr] = useState(false)
    const [zipCodeErr, setZipCodeErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [contactErr, setContactErr] = useState(false)
    //const [urlErr, setUrlErr] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (objFile === "") return
        const url = createImageUrl(objFile)
        setUrl(url)
    }, [objFile])

    useEffect(() => {
        if (imgFile === "") return
        const url = createImageUrl(imgFile)
        setBuildingImageFileList((list)=>[...list, imgFile])
        setBuildingImageList((list)=>[...list, url])
    }, [imgFile])

    const register = async (e) => {
        e.preventDefault()
        setLoading(true)
        setBuildingNameErr(false)
        setConstructionDateErr(false)
        setCompanyNameErr(false)
        setZipCodeErr(false)
        setAddressErr(false)
        setContactErr(false)
        //setUrlErr(false)

        if (buildingName === "") {
            setBuildingNameErr(true)
        }
        if(companyName === ""){
            setCompanyNameErr(true)
        }
        if(contact === ""){
            setContactErr(true)
        }
        if (url === "") {
            //setUrlErr(true)
        }

        if(buildingName !== "" && companyName !== "" && contact !== "" && url !== ""){
            // * JSON
            const data = {
                userId:userId,
                buildingName:buildingName,
                constructionDate:constructionDate,
                companyName:companyName,
                zipCode:zipCode,
                address:address,
                contact:contact
            }
            const buildingId = await api.post("addBuilding", data).then(({ data })=> data.insertId )
            // * Images
            const imageData = new FormData()
            buildingImageFileList.forEach((file, i) => imageData.append(`image_${i}`, file))
            await api.post(`addBuildingImages/${buildingId}`, imageData)
            // * ObjectFile
            const objectData = new FormData()
            objectData.append(`object`, objFile)
            await api.post(`updateBuildingObjectFile/${buildingId}`, objectData)
            setLoading(false)
            setIsRegistered(true)
        }
        setLoading(false)
    }

    return (

        <div>
            {loading ?
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center"
                }}
                >
                    <CircularProgress />
                </Box>
                :
                null
            }
            {
                !isRegistered ?
                    <>
                        < Container component="main" maxWidth="xs" >
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: "100%"
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <AddToPhotos />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    建物登録
                                </Typography>
                                <Box component="form" noValidate sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="buildingName"
                                                required
                                                fullWidth
                                                id="storeName"
                                                label="建物名"
                                                error={buildingNameErr}
                                                helperText={buildingNameErr ? "この項目は必須です" : null}
                                                autoFocus
                                                onChange={(e) => { setBuildingName(e.target.value) }}
                                                xs={12}
                                            />
                                        </Grid>

                                   <Grid item xs={12}>
                                       <TextField
                                           name="buildingName"
                                           fullWidth
                                           id="storeName"
                                           label="建設予定日(例:2000/01/04)"
                                           error={constructionDateErr}
                                           helperText={constructionDateErr ? "この項目は必須です" : null}
                                           onChange={(e) => { setConstructionDate(e.target.value) }}
                                           xs={12}
                                       />
                                   </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="companyName"
                                                required
                                                fullWidth
                                                id="companyName"
                                                label="会社名"
                                                error={companyNameErr}
                                                helperText={companyNameErr ? "この項目は必須です" : null}
                                                onChange={(e) => { setCompanyName(e.target.value) }}
                                                xs={12}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="companyName"
                                                required
                                                fullWidth
                                                id="companyName"
                                                label="連絡先"
                                                error={contactErr}
                                                helperText={contactErr ? "この項目は必須です" : null}
                                                onChange={(e) => { setContact(e.target.value) }}
                                                xs={12}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="buildingName"
                                                fullWidth
                                                id="storeName"
                                                label="建設地郵便番号"
                                                error={zipCodeErr}
                                                helperText={zipCodeErr ? "この項目は必須です" : null}
                                                onChange={(e) => { setZipCode(e.target.value) }}
                                                xs={12}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="buildingName"
                                                fullWidth
                                                id="storeName"
                                                label="建設地住所"
                                                error={addressErr}
                                                helperText={addressErr ? "この項目は必須です" : null}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                                xs={12}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                component="label"
                                                variant={url === "" ? "outlined" : "text"}
                                            >
                                                {url === "" ?
                                                    "建物ファイルを選択する"
                                                    :
                                                    <><Check />建物ファイルが選択されました</>
                                                }

                                                <input
                                                    type="file"
                                                    className="inputFileBtnHide"
                                                    onChange={(e) => { setObjFile(e.target.files[0]) }}
                                                />
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {buildingImageList.map((data, index) => {
                                                return (
                                                    <img key={index} alt="" src={data} style={{ width: "100%" }} />
                                                )
                                            })}
                                            <Button
                                                fullWidth
                                                component="label"
                                                variant={buildingImageList.length === 0 ? "outlined" : "text"}
                                            >
                                                {buildingImageList.length === 0 ?
                                                    "画像を選択する"
                                                    :
                                                    <><Check />画像を追加する</>
                                                }

                                                <input
                                                    type="file"
                                                    className="inputFileBtnHide"
                                                    onChange={(e) => { setImgFile(e.target.files[0]) }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        onClick={register}
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        登録
                                    </Button>

                                </Box>
                                <Link onClick={() => { history.goBack() }} variant="body2">
                                    戻る
                                </Link>
                            </Box>
                        </Container >
                    </ >
                    :
                    <>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <Check />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    建物の登録が完了しました。
                                </Typography>
                                <Link onClick={() => { history.goBack() }} variant="body2">
                                    戻る
                                </Link>
                            </Box>
                        </Container>
                    </>
            }
        </div>
    )
}
export default AddBuildingForm

/*
    <div>
        <input type="file" onChange={(e)=>{setObjFile(e.target.files[0])}}/>
    </div>
*/

/*
選択フォーム
<Grid item xs={12}>
                                       <FormControl sx={{ width: "100%" }} error={storeIdErr}>
                                       <InputLabel id="demo-simple-select-error-label">店舗</InputLabel>
                                       <Select
                                       labelId="demo-simple-select-error-label"
                                       id="demo-simple-select-error"
                                       value={storeId}
                                       label="店舗"
                                       onChange={(e)=>{setStoreId(e.target.value)}}
                                       >
                                       {storeList.map((data, index) => {
                                           return(
                                               <MenuItem key={index} value={data.storeId}>{data.storeName}</MenuItem>
                                           )    
                                       })}
                                       </Select>
                                       {storeIdErr ?
                                       <FormHelperText sx={{color:"red"}}>店舗名を選択してください</FormHelperText>
                                       :
                                       <FormHelperText sx={{color:"transparent"}}>店舗名を選択してください</FormHelperText>
                                       }
                                        </FormControl>
                                   </Grid>

*/