import { useState } from "react"
import { Link, useHistory } from 'react-router-dom';
import api from "../items/api"
import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LockOutlined, Email } from "@mui/icons-material";
api.defaults.withCredentials = true

function SendResetEmail() {
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)

    const history = useHistory();

    /*ボタンが押された時 */
    const decideEmail = () => {
        setEmailError(false)
        api.post("sendResetPassEmail", {
            email: email,
        }).then((response) => {
            if(response.data.result){
                alert("パスワード再設定用メールをお送りしました")
                setEmail("")
            }else{
                setEmailError(true)
            }
        })
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Email />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        パスワード再設定用メールを送信
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>

                        <TextField
                            error={emailError}
                            helperText={emailError ? "このメールアドレスは登録されていません" : null}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            type="email"
                            label="メールアドレス"
                            autocapitalize="off"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e)=>{setEmail(e.target.value)}}
                        />

                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={decideEmail}
                        >
                            送信
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    )
}
export default SendResetEmail