import { useEffect, useState } from "react"
import api from "../component/items/api"

const useBuildingList = (userId, load) => {
    const [buildingList, setBuildingList] = useState([])
    useEffect(() => {
        api.get(`getBuildingList/${userId}`).then((response) => {
            console.log(response.data.buildingList)
            setBuildingList(response.data.buildingList)
        })
    },[userId, load])
    return buildingList
}
export default useBuildingList