import { useState } from "react"
import { useHistory, Link } from 'react-router-dom';
import api from "../items/api"
import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Check, LockOutlined } from "@mui/icons-material";

function SignUp() {
    /* step0 */
    const [userName, setUserName] = useState("")
    const [homePage, setHomePage] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [address, setAddress] = useState("")
    const [password, setPassword] = useState("")
    const [passCheck, setPassCheck] = useState("")
    const [userNameErr, setUserNameErr] = useState(false)
    const [homePageErr, setHomePageErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [phoneNumberErr, setPhoneNumberErr] = useState(false)
    const [zipCodeErr, setZipCodeErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [passErr, setPassErr] = useState(false)

    /* step1 */
    const [authCode, setAuthCode] = useState("")/* 作成される認証コード */
    const [authCheck, setAuthCheck] = useState("")/* ユーザーが入力するコード */
    const [authCheckErr, setAuthCheckErr] = useState(false)

    /* step管理 */
    const [step ,setStep] = useState(0)
    const history = useHistory();


    /*step0完了ボタンが押されたとき */
    const registerStep0 = (e) => {
        e.preventDefault()
        setUserNameErr(false)
        setHomePageErr(false)
        setEmailErr(false)
        setPhoneNumberErr(false)
        setZipCodeErr(false)
        setAddressErr(false)
        setPassErr(false)
        if (password === passCheck && userName !== "" && homePage !== "" && email !== "" && phoneNumber !== "" && zipCode !== "" && address !== "") {
            /* 認証コードの作成 */
            const S = "0123456789";
            const N = 6;
            const newCode = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n) => S[n % S.length]).join('');
            setAuthCode(newCode)
            /* メールがすでにあるかどうか確認し、認証メールを送信 */
            api.post("sendAuthEmail", {
                email:email,
                authCode:newCode
            }).then((response) => {
                if(response.data.result){
                    setStep(1)
                }else{
                    alert("このメールアドレスは既に使用されています")
                }
            })

        } else {
            if(password !== passCheck){
                setPassErr(true)
            }
            if(userName === ""){
                setUserNameErr(true)
            }
            if(homePage === ""){
                setHomePageErr(true)
            }
            if(email === ""){
                setEmailErr(true)
            }
            if(phoneNumber === ""){
                setPhoneNumber(true)
            }
            if(zipCode === ""){
                setZipCodeErr(true)
            }
            if(address === ""){
                setAddressErr(true)
            }

        }

    }
    /* step1完了 */
    const registerStep1 = () => {
        setAuthCheckErr(false)
        console.log(authCode)
        /* 認証コードを確認 */
        if(authCode !== authCheck){
            setAuthCheckErr(true)
            return
        }
        /* 正しい場合次の処理をする */
        api.post("signup", {
            userType:1,
            userName: userName,
            homePage:homePage,
            email: email,
            phoneNumber:phoneNumber,
            zipCode:zipCode,
            address:address,
            password: password,
        }).then((response) => {
            setStep(2)
        })
    }


    /*ログインページに行く */
    const goLogin = () => {
        history.push("/login")
    }
    const backpage = () => {
        history.goBack()
    }

    if(step === 0){
        return(
            < Container component="main" maxWidth="xs" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        新規登録
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="userName"
                                    required
                                    fullWidth
                                    error={userNameErr}
                                    helperText={userNameErr ? "この項目は必須です" : null}
                                    id="userName"
                                    label="会社名"
                                    autoFocus
                                    onChange={(e) => { setUserName(e.target.value) }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="homePage"
                                    required
                                    fullWidth
                                    id="homePage"
                                    label="会社ホームページ"
                                    error={homePageErr}
                                    helperText={homePageErr ? "この項目は必須です" : null}
                                    autoFocus
                                    onChange={(e) => { setHomePage(e.target.value) }}
                                />
                            </Grid>
                            
                    
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="メールアドレス"
                                    name="email"
                                    autoComplete="email"
                                    error={emailErr}
                                    helperText={emailErr ? "この項目は必須です" : null}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="phoneNumber"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label="電話番号"
                                    error={phoneNumberErr}
                                    helperText={phoneNumberErr ? "この項目は必須です" : null}
                                    onChange={(e) => { setPhoneNumber(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="zipCode"
                                    required
                                    fullWidth
                                    id="postNumber"
                                    label="会社郵便番号"
                                    error={zipCodeErr}
                                    helperText={zipCodeErr ? "この項目は必須です" : null}
                                    onChange={(e) => { setZipCode(e.target.value) }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="address"
                                    required
                                    fullWidth
                                    id="address"
                                    label="会社住所"
                                    error={addressErr}
                                    helperText={addressErr ? "この項目は必須です" : null}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="パスワード"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    error={passErr}
                                    helperText={passErr ? "パスワードが一致しません" : null}
                                    name="password"
                                    label="パスワード(確認)"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => { setPassCheck(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            onClick={registerStep0}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            次へ
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link onClick={backpage} variant="body2">
                                    戻る
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/login" variant="body2">
                                    アカウント既に持っている場合はログイン
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container >
        )
    }else if(step === 1){
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        認証コードを入力してください
                    </Typography>
                    <Typography component="h5" variant="p" sx={{ mt: 2 }}>
                        以下のメールに送信されました<br/>
                        {email}
                    </Typography>

                    <TextField
                        name="authCode"
                        required
                        fullWidth
                        id="authCode"
                        label="コード"
                        error={authCheckErr}
                        helperText={authCheckErr ? "認証コードが間違っています" : null}
                        onChange={(e) => { setAuthCheck(e.target.value) }}
                    />
                    <Button
                        onClick={registerStep1}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        認証
                    </Button>
                </Box>
            </Container>
        )
    }else{
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Check />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        新規登録が完了しました。
                    </Typography>
                    <Typography component="h1" variant="body1" sx={{ mt: 2 }}>
                        ログインしてサービスをお楽しみください。
                    </Typography>
                    <Button
                        onClick={goLogin}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        ログインする
                    </Button>
                </Box>
            </Container>
        )
    }
}
export default SignUp