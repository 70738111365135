import * as React from 'react';
import { useState } from "react"
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import api from "../items/api"
import { Modal, Tooltip, Button } from '@mui/material';
import { useHistory } from "react-router-dom"
import {Edit, Delete } from "@mui/icons-material"
import useInstallSignBoardList from '../../hooks/useInstallSignBoardList';
import useRoomList from '../../hooks/useRoomList';


function Row(props) {
  const { row, loading } = props;
  const history = useHistory()
  const roomList = useRoomList(row)
  const installSignBoardList = useInstallSignBoardList(row)
  const [open, setOpen] = useState(false);
  const [mOpen, setMOpen] = useState(false)
  const [mOpen2, setMOpen2] = useState(false)
  const [mOpen3, setMOpen3] = useState(false)
  const [roomId, setRoomId] = useState(0)
  const [signBoardInstallId, setSignBoardInstallId] = useState(0)
  const date = new Date(row.constructionDate)
  const disDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:"center"
  };

  const deleteBuilding = () => {
    api.post(`deleteBuilding/${row.buildingId}`).then((response) => {
      loading()
      setMOpen(false)
    })
  }
  const deleteRoom = () => {
    api.post(`deleteRoom/${roomId}`).then((response) => {
      loading()
      setMOpen2(false)
    })
  }
  const deleteInstallSignBoard = () => {
    api.post(`deleteInstallSignBoard`, { signBoardInstallId: signBoardInstallId }).then((response) => {
      loading()
      setMOpen3(false)
    })
  }
  const goEditBuilding = () => {
    history.push({pathname:"/editBuilding", state:{data:row,constructionDate:disDate}})
  }
  const goEditRoom = (roomId, roomName, rent, floor, image) => {
    history.push({pathname:"/editRoom", state:{roomId:roomId, roomName:roomName, rent:rent, floor:floor, image:image}})
  }

  return (
    <React.Fragment>
      <TableRow sx={{width:"100%"}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" component="th">
          {row.buildingName}
        </TableCell>
        <TableCell scope="row" component="th">
          {row.companyName}
        </TableCell>
        <TableCell scope="row" component="th">
          {row.userId === 2 ?
          <a href={`https://app.metavuild.com?groupid=f4ly4&buildingid=${row.buildingId}`}>
          https://app.metavuild.com?groupid=f4ly4&buildingid={row.buildingId}
          </a>
          :
          <>
          {row.userId === 3 ?
          <a href={`https://app.metavuild.com?groupid=afurl&buildingid=${row.buildingId}`}>
          https://app.metavuild.com?groupid=afurl&buildingid={row.buildingId}
          </a>
          :
          <a href={`https://app.metavuild.com?buildingid=${row.buildingId}`}>
          https://app.metavuild.com?buildingid={row.buildingId}
          </a>
          }
          </>
          }
        </TableCell>
        <TableCell align="right">{row.contact}</TableCell>
        <TableCell align="right"><a href={row.url}>ダウンロード</a></TableCell>
        <TableCell align="right">
            <Box sx={{margin:0, marginLeft:"auto"}}>
                <Tooltip title="編集">
                    <IconButton onClick={goEditBuilding} sx={{padding:0}}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="削除" sx={{marginLeft:"50px", backgroundColor:"pink"}}>
                    <IconButton onClick={()=>{setMOpen(true)}} sx={{padding:0, marginLeft:"10px"}}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        </TableCell>
      </TableRow>

      <TableRow sx={{borderBottom:"1px 	#e6e6e6 solid"}}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="label" sx={{fontSize:"1000"}}>
                部屋情報
              </Typography>
              <Button onClick={()=>{history.push({pathname:"/addRoom", state:row.buildingId})}} variant="outlined" sx={{marginLeft:"15px"}}>部屋を追加</Button>
              <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>部屋名</TableCell>
                  <TableCell>家賃</TableCell>
                  <TableCell align="right">階数</TableCell>
                  <TableCell align="right">イメージ画像</TableCell>
                  <TableCell align="right">編集・削除</TableCell>
                </TableRow>
              </TableHead>
                {roomList.length > 0 ?
                <TableBody>
                {roomList.map((data, index) => {
                  return(
                    <TableRow key={index}>
                      <TableCell>{data.roomName}</TableCell>
                      <TableCell>{data.rent}</TableCell>
                      <TableCell align="right">{data.floor}</TableCell>
                      <TableCell align="right"><a href={data.image}>ダウンロード</a></TableCell>
                      <TableCell align="right">
                        <Box sx={{margin:0, marginLeft:"auto"}}>
                          <Tooltip title="編集">
                          <IconButton onClick={()=>{goEditRoom(data.roomId, data.roomName, data.rent, data.floor, data.image)}} sx={{padding:0}}>
                            <Edit />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="削除" sx={{marginLeft:"50px", backgroundColor:"pink"}}>
                              <IconButton onClick={()=>{setMOpen2(true);setRoomId(data.roomId)}} sx={{padding:0, marginLeft:"10px"}}>
                                  <Delete />
                              </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
                :
                null
                }
            </Table>
            </Box>

            <Box sx={{ margin: 1, mt: 4 }}>
              <Typography variant="h6" gutterBottom component="label" sx={{fontSize:"1000"}}>
                看板情報
              </Typography>
              <Button 
                onClick={()=>{history.push({pathname:"/addInstallSignBoard", state:row.buildingId})}} 
                variant="outlined" 
                sx={{marginLeft:"15px"}}
              >
                看板を追加
              </Button>
              <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>看板名</TableCell>
                  <TableCell>リンクURL</TableCell>
                  <TableCell>画像</TableCell>
                  <TableCell align="right">削除</TableCell>
                </TableRow>
              </TableHead>
                {installSignBoardList.length > 0 ?
                <TableBody>
                {installSignBoardList.map((data, index) => {
                  return(
                    <TableRow key={index}>
                      <TableCell>{data.signBoardName}</TableCell>
                      <TableCell>{data.url}</TableCell>
                      <TableCell>
                        <img src={data.image} alt="" style={{ maxHeight: "30px" }} />
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{margin:0, marginLeft:"auto"}}>
                          <Tooltip title="削除" sx={{marginLeft:"50px", backgroundColor:"pink"}}>
                              <IconButton onClick={()=>{setMOpen3(true);setSignBoardInstallId(data.signBoardInstallId)}} sx={{padding:0, marginLeft:"10px"}}>
                                  <Delete />
                              </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
                :
                null
                }
            </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={mOpen}
        onClose={()=>{setMOpen(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            本当に削除しますか？
            </Typography>
            <Button onClick={deleteBuilding} variant="contained" sx={{marginTop:"30px"}} color="error">削除する</Button>
            <Button onClick={()=>{setMOpen(false)}} variant="outlined" sx={{marginTop:"30px", marginLeft:"30px"}} color="success">閉じる</Button>
        </Box>
      </Modal>
      <Modal
        open={mOpen2}
        onClose={()=>{setMOpen2(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            本当に削除しますか？
            </Typography>
            <Button onClick={deleteRoom} variant="contained" sx={{marginTop:"30px"}} color="error">削除する</Button>
            <Button onClick={()=>{setMOpen2(false)}} variant="outlined" sx={{marginTop:"30px", marginLeft:"30px"}} color="success">閉じる</Button>
        </Box>
      </Modal>
      <Modal
        open={mOpen3}
        onClose={()=>{setMOpen3(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            本当に削除しますか？
            </Typography>
            <Button onClick={deleteInstallSignBoard} variant="contained" sx={{marginTop:"30px"}} color="error">削除する</Button>
            <Button onClick={()=>{setMOpen3(false)}} variant="outlined" sx={{marginTop:"30px", marginLeft:"30px"}} color="success">閉じる</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
    const {buildingList, loading} = props
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>建物名</TableCell>
            <TableCell>会社名</TableCell>
            <TableCell>URL</TableCell>
            <TableCell align="right">連絡先</TableCell>
            <TableCell align="right">ファイル</TableCell>
            <TableCell align="right">編集・削除</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildingList.map((row,index) => {
            return(
              <Row key={index} row={row} sx={{width:"100%"}} loading={loading}/>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}